export const WarningIcon = (props: any) => (
  <div {...props}>
    <svg
      width='12'
      height='13'
      viewBox='0 0 12 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.988987'
        d='M5.99935 0.674927C2.7776 0.674927 0.166016 3.28651 0.166016 6.50826C0.166016 9.73001 2.7776 12.3416 5.99935 12.3416C9.2211 12.3416 11.8327 9.73001 11.8327 6.50826C11.8327 3.28651 9.2211 0.674927 5.99935 0.674927ZM5.99935 1.84159C8.57652 1.84159 10.666 3.93109 10.666 6.50826C10.666 9.08543 8.57652 11.1749 5.99935 11.1749C3.42218 11.1749 1.33268 9.08543 1.33268 6.50826C1.33268 3.93109 3.42218 1.84159 5.99935 1.84159ZM5.99935 3.59159C5.67735 3.59159 5.41602 3.85293 5.41602 4.17493C5.41602 4.49693 5.67735 4.75826 5.99935 4.75826C6.32135 4.75826 6.58268 4.49693 6.58268 4.17493C6.58268 3.85293 6.32135 3.59159 5.99935 3.59159ZM5.41602 5.34159C5.09402 5.34159 4.83268 5.60293 4.83268 5.92493C4.83268 6.20609 5.04211 6.41609 5.30636 6.47209L4.96043 8.1486C4.8251 8.82585 5.30927 9.42493 5.99935 9.42493H6.58268C6.90468 9.42493 7.16602 9.16359 7.16602 8.84159C7.16602 8.51959 6.90468 8.25826 6.58268 8.25826H6.1271L6.5646 6.03459C6.63693 5.6735 6.36743 5.34159 5.99935 5.34159C5.92585 5.34159 5.51052 5.34159 5.41602 5.34159Z'
        fill='#BDBDBD'
      />
    </svg>
  </div>
);